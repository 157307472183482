import Decimal from "decimal.js";

export const minToS = (min) => ({
	s: min * 60,
	ms: min * 60 * 1000,
});

export const dayToS = (day = 1) => ({
	s: day * 24 * 60 * 60,
	ms: day * 24 * 60 * 60 * 1000,
});

export function msToTime(s) {
	// Pad to 2 or 3 digits, default is 2
	function pad(n, z) {
		z = z || 2;
		return ("00" + n).slice(-z);
	}

	var ms = s % 1000;
	s = (s - ms) / 1000;
	var secs = s % 60;
	s = (s - secs) / 60;
	var mins = s % 60;
	var hrs = (s - mins) / 60;

	return { hrs: pad(hrs), mins: pad(mins), secs: pad(secs), ms: pad(ms, 3) };
}

export const formatTime = (timer) => {
	// console.log({ timeTimer: timer });
	timer = timer || 0;
	let AllMinutes = `${Math.floor(timer / 60)}`;
	let seconds = `0${timer % 60}`.slice(-2);
	let minutes = `0${AllMinutes % 60}`.slice(-2);
	let hours = `0${Math.floor(timer / 3600)}`.slice(-2);

	// return ` ${minutes} : ${seconds}`
	return {
		seconds: seconds || "00",
		minutes: minutes || "00",
		hours: hours || "00",
		timer,
	};
};

export function numberWithCommas(x = 0) {
	x = String(x) || "0";
	const [a, b] = x?.split?.(".");
	const c = a?.toString?.().replace?.(/\B(?=(\d{3})+(?!\d))/g, ",");
	// console.log({ a, b, c });
	return b ? [c, b].join(".") || 0 : c || 0;
	// (/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
}

export const changePriceFormat = (v, f = 2) => {
	// console.log({ v, f });
	if (!!!v) return 0;
	return parseFloat(v);
	// ?.toFixed(2);
};

export const changePriceFormatAfterZero = (v, f = 2) => {
	// console.log({ v, f });
	if (!!!v) return 0;
	let decimalSplit = String(v).split(".");
	// let n = Math.floor(Math.log(decimalSplit[1]) / Math.LN10);
	let n = -Math.floor(Math.log(decimalSplit[1]) / Math.log(10) + 1);
	// console.log({ n, v, decimalSplit }, Math.LN10, Math.log(v), ((n) + decimalSplit[1]?.length) + f);
	if (decimalSplit[1]?.length) {
		return decimalSplit[0] + "." + decimalSplit[1].substring(0, n + decimalSplit[1]?.length + f);
	} else return decimalSplit[0];
};

export function percentage(a, b) {
	return new Decimal(a).mul(b).div(100) || 0;
}

// export function calcPercentNumber(a, b) {
// 	return new Decimal(a).div(new Decimal(a).add(b)).mul(100) || 0;
// }
export function calcPercentNumber(a, b) {
	return (b / a) * 100;
}
